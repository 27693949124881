import React, { useState } from 'react';
import chunk from 'lodash/chunk';
import Flickity from './Flickity';
import Link from './Link';

const MOBILE_SLIDE_OPTIONS = {
	cellAlign: 'center',
	freeScroll: true,
	contain: false,
	wrapAround: true,
	prevNextButtons: false,
	pageDots: true,
	selectedAttraction: 0.1,
	friction: 0.4,
};

const DESKTOP_SLIDE_OPTIONS = {
	cellAlign: 'left',
	contain: true,
	wrapAround: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
	
};

const ProductsCarouselWithArrow = ({
	title,
	products = [],
}) => {
	const [carousel, setCarousel] = useState(null);

	const inventoryProducts = products;

	const desktopProductTileChunks = chunk(inventoryProducts, 3);

	const onClickPrevious = () => {
		if (!carousel) return;
		carousel.previous();
	};

	const onClickNext = () => {
		if (!carousel) return;
		carousel.next();
	};

	const handleScroll = (e) => {
		e.preventDefault(); // Prevent default anchor click behavior
		const target = document.getElementById('where-to-buy');
		if (target) {
			// Calculate target position with a 10% margin from the top of the viewport
			const offsetTop = target.getBoundingClientRect().top + window.scrollY;
			const marginOffset = window.innerHeight * 0.18; // 15% of the viewport height
			
			// Scroll to the target with the margin
			window.scrollTo({
				top: offsetTop - marginOffset, 
				behavior: 'smooth'
			});
		}
	};

	const mobileProductCarousel = (
		<Flickity
			key={inventoryProducts.map(p => p.name).join('-')} 
			className="product-carousel hide--lg"
			options={MOBILE_SLIDE_OPTIONS}>
			{products.map((item, index) => (
				<div className="product-carousel__item mr3 product-card" key={index}>
					<div>
						<a href="javascript:void(0)"  onClick={handleScroll} className="db link--opacity mb1" aria-label={'Product - ' + item.name}>
							<div className="image size--3x4">
								<picture>
									<img
										alt={item.image.alt}
										src={`${item.image.url}?w=500&h=666&auto=format`}
									/>
								</picture>
							</div>
						</a>
						<div className=" fdr jcb aib mb1 text-center">
							<a
								className="link--opacity fw--800"
								href="javascript:void(0)"  onClick={handleScroll} aria-label={item.name}>
								{item.name}
							</a>
						</div>
						<div className="text-center fw--400 mb4">
							{item.shot_description}
						</div>
						<div className="mb1 text-center product-tile__reviews">
							<button
								className="buy-now-btn"
								href="javascript:void(0)"  onClick={handleScroll} aria-label={'Buy Now (' + item.name.replace(/-/g, ' ') + ')'}>
								Buy now
							</button>
						</div>
					</div>


				</div>
			))}
		</Flickity>
	);

	const desktopProductCarousel = (
		<div className="row align--middle show--lg">
			<div className="col c1">
				{desktopProductTileChunks.length > 1 && (
					<button
						title="Product"
						aria-label="Product"
						onClick={onClickPrevious}
						className="db link--opacity mra">
						<img
							className="db flip"
							src="/images/icon-arrow-quiz.svg"
							alt="Arrow"
						/>
					</button>
				)}
			</div>
			<div className="col c10">
				<Flickity
					sliderRef={sliderRef => setCarousel(sliderRef)}
					key={inventoryProducts.map(p => p.name).join('-')} 
					options={DESKTOP_SLIDE_OPTIONS}>
					{desktopProductTileChunks.map((rowProducts, index) => (
						<div key={index} className="x mr3">
							<div className="row gutter--large--xl">
								{rowProducts.map((item, index) => (
									<div key={index} className="col c4 product-card">
										<div>
											<a href="javascript:void(0)"  onClick={handleScroll} className="db link--opacity mb1" aria-label={'Product - '+ item.name}>
												<div className="image size--3x4">
													<picture>
														<img
															alt={item.image.alt}
															src={`${item.image.url}?w=500&h=666&auto=format`}
														/>
													</picture>
												</div>
											</a>
											<div className=" fdr jcb aib mb1 text-center">
												<a
													className="link--opacity fw--800"
													href="javascript:void(0)"  onClick={handleScroll} aria-label={item.name}>
													{item.name}
												</a>
											</div>
											<div className="text-center fw--400 mb4">
												{item.shot_description}
											</div>
											<div className="mb1 text-center product-tile__reviews">
												<button
													className="buy-now-btn"
													href="javascript:void(0)"  onClick={handleScroll} aria-label={'Buy Now ('+ item.name.replace(/-/g, ' ')+')'}>
													Buy now
												</button>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</Flickity>
			</div>
			<div className="col c1">
				{desktopProductTileChunks.length > 1 && (
					<button
						title="Product"
						aria-label="Product"
						onClick={onClickNext}
						className="db link--opacity mla">
						<img className="db" src="/images/icon-arrow-quiz.svg" alt="Arrow" />
					</button>
				)}
			</div>
		</div>
	);

	return (
		<div
			id="product-quiz"
			className="bg--white pr of--hidden custom-landing-page">
			<div className="grid-container contained product-sale">
			<div className='mb8 page-heading full-width text-center align-items-center justify-content-center'>
					<h1 className='tc fw--800 h1'>{title}</h1>
				</div>
				{mobileProductCarousel}
				{desktopProductCarousel}
			</div>
		</div>
	);
};

export default (ProductsCarouselWithArrow);
