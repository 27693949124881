import React, { useEffect, useRef, useState } from 'react';

const LandingPageCompare = ({
  title,
  sub_title,
  button_text,
  link,
  genexa_image,
  other_brand_image,
  genexa_image_mobile,
  other_brand_image_mobile

}) => {
  const [isLeftHovered, setIsLeftHovered] = useState(false);
  const [isRightHovered, setIsRightHovered] = useState(false);
  const [beforeWidth, setBeforeWidth] = useState('50%'); // Dynamic width for before image
  const [afterWidth, setAfterWidth] = useState('50%');   // Dynamic width for after image
  const [showLeftDrag, setShowLeftDrag] = useState(false); // Track left drag visibility
  const [showRightDrag, setShowRightDrag] = useState(false); // Track right drag visibility
  const [handlerVisible, setHandlerVisible] = useState(true); // Track handler button visibility
  const [sliderVisible, setSliderVisible] = useState(true); // Track visibility of the comparison slider
  const [sliderHandlerVisible, setSliderHandlerVisible] = useState(true); // Track visibility of the comparison slider handle

  const [isResizing, setIsResizing] = useState(false);
  const [leftWidth, setLeftWidth] = useState(50); // Initial width as 50% of the container
  const [rightWidth, setRightWidth] = useState(50); // Initial width as 50% for the right div
  const [leftScrollReached, setLeftScrollReached] = useState(false);
  const [rightScrollReached, setRightScrollReached] = useState(false);
  const [hideLeftContent, setHideLeftContent] = useState(false);
  const [hideRightContent, setHideRightContent] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(50); // For mobile slider
  const [isDragging, setIsDragging] = useState(false); // For mobile slider
  const [activeImage, setActiveImage] = useState(null); // Tracks which image is currently active

  const leftDivRef = useRef(null);
  const rightDivRef = useRef(null);
  const containerRef = useRef(null);
  const resizeHandleRef = useRef(null);
  const [isMobileSliderVisible, setIsMobileSliderVisible] = useState(true);

  //Hover Logic
  const handleLeftDivHover = () => {
    setLeftWidth(67); // Expand left div
    setRightWidth(33); // Shrink right div
    setLeftScrollReached(true); // Show afterscroll content in left div
    setHideRightContent(true); // Hide right content
    setIsLeftHovered(true);
    setIsRightHovered(false);
  };

  const handleRightDivHover = () => {
    setRightWidth(67); // Expand right div
    setLeftWidth(33); // Shrink left div
    setRightScrollReached(true); // Show afterscroll content in right div
    setHideLeftContent(true); // Hide left content
    setIsRightHovered(true);
    setIsLeftHovered(false);
  };

  const handleMouseLeave = () => {
    setLeftWidth(50); // Reset left div width
    setRightWidth(50); // Reset right div width
    setLeftScrollReached(false); // Hide afterscroll in left div
    setRightScrollReached(false); // Hide afterscroll in right div
    setHideLeftContent(false); // Show left content
    setHideRightContent(false); // Show right content
    setIsLeftHovered(false);
    setIsRightHovered(false); 
  };

  // Desktop slider logic
  useEffect(() => {
    const leftDiv = leftDivRef.current;
    const resizeHandle = resizeHandleRef.current;
  
    let initialX = 0;
    let initialLeftWidth = 0;
  
    const resizeDiv = (e) => {
      if (isResizing) {
        const deltaX = e.clientX - initialX; // Mouse position change
        const containerWidth = leftDiv.parentNode.offsetWidth; // Get container width
        const newLeftWidthPercentage = ((initialLeftWidth + deltaX) / containerWidth) * 100;
    
        // Ensure left div width stays between 30% and 70%
        if (newLeftWidthPercentage >= 35 && newLeftWidthPercentage <= 65) {
          setLeftWidth(newLeftWidthPercentage);
          setRightWidth(100 - newLeftWidthPercentage);
    
          // Hide right content when left div is dragged more than 60%
          if (newLeftWidthPercentage >= 60) {
            setHideRightContent(true); // Hide right content
          } else {
            setHideRightContent(false); // Show right content
          }
    
          // Hide left content when right div is dragged more than 60%
          if (100 - newLeftWidthPercentage >= 60) {
            setHideLeftContent(true); // Hide left content
          } else {
            setHideLeftContent(false); // Show left content
          }
    
          // Show `afterscrolldiv` when left div reaches 60% to 70%
          if (newLeftWidthPercentage >= 60) {
            setLeftScrollReached(true); // Show left `afterscrolldiv`
          } else {
            setLeftScrollReached(false); // Hide left `afterscrolldiv`
          }
    
          // Show `afterscrolldiv` when right div reaches 60% to 70%
          if (100 - newLeftWidthPercentage >= 60) {
            setRightScrollReached(true); // Show right `afterscrolldiv`
          } else {
            setRightScrollReached(false); // Hide right `afterscrolldiv`
          }
        }
      }
    };
    
    
  
    const stopResizing = () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', resizeDiv);
      document.removeEventListener('mouseup', stopResizing);
    };
  
    const handleMouseDown = (e) => {
      setIsResizing(true);
      initialX = e.clientX;
      initialLeftWidth = leftDiv.offsetWidth;
  
      document.addEventListener('mousemove', resizeDiv);
      document.addEventListener('mouseup', stopResizing);
    };
  
    return () => {
      document.removeEventListener('mousemove', resizeDiv);
      document.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing]);

/***** New Slider *************/
const [slideIndex, setSlideIndex] = useState(2); // Set to 2 to make the second slide active by default

useEffect(() => {
  showSlides(slideIndex);
}, [slideIndex]);

const showSlides = (n) => {
  let i;
  let slides = document.getElementsByClassName("mySlides");
  let dots = document.getElementsByClassName("dot");

  if (n > slides.length) {
    setSlideIndex(1);
  }
  if (n < 1) {
    setSlideIndex(slides.length);
  }

  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }

  if (slides[slideIndex - 1]) {
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
  }
};

const plusSlides = (n) => {
  setSlideIndex((prev) => {
    const newSlideIndex = prev + n;
    if (newSlideIndex > 3) return 1; // Reset to 1 if going beyond slide 3
    if (newSlideIndex < 1) return 3; // Reset to 3 if going before slide 1
    return newSlideIndex;
  });
};

const currentSlide = (n) => {
  setSlideIndex(n);
};

  return (
    <section className="custom-landing-page display-flex grid-container flush justify-content-center pr">
      <div className="full-width gradient-bg product-draggable">
        <div className="for-desktop mb6 page-heading grid-container contained text-center align-items-center justify-content-center">
          {/* <h3 className="tc h3">{title}</h3>
          <h2 className="tc h2">{sub_title}</h2> */}
          <img alt="Genexa" src='/images/heading.png' />
        </div>
        {/*************New Desktop Slider****************/}
        <div className='slider-container df for-desktop' style={{ display: 'flex', width: '100%' }}>
          <div
             ref={leftDivRef}
             className={`left-container slider-wrap pr df ${isLeftHovered ? 'active' : isRightHovered ? 'inactive' : ''}`}
             style={{ width: `calc(${leftWidth}% - 0px)`, flexShrink: 0, transition: 'width 0.5s ease' }}
             onMouseEnter={handleLeftDivHover}
             onMouseLeave={handleMouseLeave}
          >
            <div className='slider-content left-slider-content df'>
              <div className={`only-content df ${hideLeftContent ? 'hide' : ''}`}>
                <div className={`before-scroll ${leftScrollReached ? 'hide' : ''}`}>
                  <h2>LEADING<span> BRANDS</span></h2>
                  <h3>UP TO</h3>
                  <h5>90<span>% </span></h5>
                  <h6>UNWANTED <span>FILLERS</span></h6>
                </div>
                <div className={`after-scroll ${leftScrollReached ? '' : 'hide'}`}>
                  <h2>LEADING<span> BRANDS</span></h2>
                  <h4>Competitors use the same effective medicine, but they also may have:</h4>
                  <ul>
                    <li><span></span>Artificial Preservatives</li>
                    <li><span></span> Artificial Sweeteners</li>
                    <li><span></span> Artificial Dyes</li>
                  </ul>
                </div>
              </div>
              <div className='genexa-image'>
                <picture>
                  <img alt="Genexa" src='/images/New-Comparison_Bottles.png' />
                </picture>
              </div>
            </div>
          </div>
          <div
            ref={rightDivRef}
            className={`right-container slider-wrap pr df ${isRightHovered ? 'active' : isLeftHovered ? 'inactive' : ''}`}
            style={{ width: `calc(${rightWidth}% - 0px)`, flexShrink: 0, transition: 'width 0.5s ease' }}
            onMouseEnter={handleRightDivHover}
            onMouseLeave={handleMouseLeave}
          >
            <div className='slider-content right-slider-content pr df'>
              <div className='berry-wrap'>
                <img alt="Berry Image1" className='berry-image berry1' src='/images/berry1.png' />
                <img alt="Berry Image2" className='berry-image berry2' src='/images/berry2.png' />
                <img alt="Berry Image3" className='berry-image berry3' src='/images/berry3.png' />
                <img alt="Berry Image4" className='berry-image berry4' src='/images/berry4.png' />
              </div>
              <div className={`only-content df ${hideRightContent ? 'hide' : ''}`}>
                <div className={`before-scroll ${rightScrollReached ? 'hide' : ''}`}>
                  <div className='logo-class'>
                    <picture>
                      <img alt="logo" src='/images/slider-logo.png' />
                    </picture>
                  </div>
                  <h3>ALWAYS</h3>
                  <h5>0<span>%</span></h5>
                  <h6>ARTIFICIAL <span>ADDITIVES</span></h6>
                </div>
                <div className={`after-scroll ${rightScrollReached ? '' : 'hide'}`}>
                  <div className='logo-class'>
                    <picture>
                      <img alt="logo" src='/images/slider-logo.png' />
                    </picture>
                  </div>
                  <h4>Genexa has the same effective medicine, and has:</h4>
                  <ul>
                    <li><span></span>0% artificial preservatives,
                    flavors, and sweeteners </li>
                    <li><span></span>0% dyes and common allergens </li>
                    <li >AND, we’re made in the USA with
                  Non-GMO globally sourced ingredients.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /************ New Slider  *************/}
        <div className='mobile-new-slider for-mobile'>
          <div className="slideshow-container">
            <div className="mySlides fade">
              <img src="/images/LandingPage_MOBILE_Comparison_3@2x.png" alt="compare brand 1" style={{ width: '100%' }} />
            </div>

            <div className="mySlides fade">
              <img src="/images/LandingPage_MOBILE_Comparison_1@2x.png" alt="compare brand 2" style={{ width: '100%' }} />
            </div>

            <div className="mySlides fade">
              <img src="/images/LandingPage_MOBILE_Comparison_2@2x.png" alt="compare brand 3" style={{ width: '100%' }} />
            </div>

            {/* Conditionally render the Previous button */}
            {slideIndex > 1 && (
              <a role="button" tabindex="0" aria-label="previous"
                className={`prev ${slideIndex === 2 ? "second-slide-active" : ""}`}
                onClick={() => plusSlides(-1)}
              >
                ❮
              </a>
            )}
            
            {/* Conditionally render the Next button */}
            {slideIndex < 3 && (
              <a role="button" tabindex="0" aria-label="next"
                className={`next ${slideIndex === 2 ? "second-slide-active" : ""}`}
                onClick={() => plusSlides(1)}
              >
                ❯
              </a>
            )}

            <div className='dot-div' style={{ textAlign: 'center' }}>
              <span className="dot" onClick={() => currentSlide(1)}></span>
              <span className="dot" onClick={() => currentSlide(2)}></span>
              <span className="dot" onClick={() => currentSlide(3)}></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPageCompare;