import React from 'react';

const HeroBannerHomePage = ({
	_key,
	desktopBackground,
	mobileBackground,
	link
}) => {
	
	return (
<>
<div className="col c12 c12--lg testimonial-col" style={{'overflow':'hidden', 'display':'block'}}>
	<div className="testimonial__carousel-container" style={{'width':'100vw'}}>
		<div className="db image homepage-banner-aspect-ratio" style={{
          position: 'relative', // For absolute positioning of child elements
        }}>
		<a href={link} aria-label="Hero-Banner">
			<picture>
				<source
                    alt={desktopBackground.alt}
					srcSet={`${desktopBackground.url}?auto=format&amp;q=100`}
					media="(min-width: 800px)"
				/>
				<source
                    alt={mobileBackground.alt}
					srcSet={`${mobileBackground.url}?auto=format&amp;q=100`}
					media="(max-width: 799px)"
				/>
				<img
					alt={desktopBackground.alt}
					src={`${desktopBackground.url}?auto=format&amp;q=100`}
					data-cmp-ab="2"
					data-cmp-info="10"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						objectPosition: 'center',
						position: 'absolute', // Ensure the image fills the container
						top: 0,
						left: 0,
					}}
				/>
			</picture>
			</a>
		</div>
	</div>
</div>
</>
)};
export default HeroBannerHomePage;
