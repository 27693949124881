import React from 'react';
import DoctorsBioGrid from './DoctorsBioGrid';
import DoctorsModalGrid from './DoctorsModalGrid';
import HeroHome from './HeroHome';
import HeroCarousel from './HeroCarousel';
import HeroPage from './HeroPage';
import HeroPartner from './HeroPartner';
import DuotoneBumper from './DuotoneBumper';
import IngredientOverview from './IngredientOverview';
import IngredientComparisonCarousel from './IngredientComparisonCarousel';
import ImageCarousel from './ImageCarousel';
import CertificationsList from './CertificationsList';
import CertificationsGrid from './CertificationsGrid';
import Testimonial from './Testimonial';
import TestimonialCarousel from './TestimonialCarousel';
import InfoPage from './InfoPage';
import RichTextColumns from './RichTextColumns';
import PressTestimonials from './PressTestimonials';
import PressGrid from './PressGrid';
import StoreLocator from './StoreLocator';
import InstagramGrid from './InstagramGrid';
import FullWidthForm from './FullWidthForm';
import FullWidthKlaviyoForm from './FullWidthKlaviyoForm';
import HubspotForm from './HubspotForm';
import FaqPage from './FaqPage';
import ProductQuiz from './ProductQuiz';
import CalloutCollection from './CalloutCollection';
import HorizontalRule from './HorizontalRule';
import MediaWithRichText from './MediaWithRichText';
import FullWidthMedia from './FullWidthMedia';
import FullWidthRichText from './FullWidthRichText';
import FullWidthReviews from './FullWidthReviews';
import CuralateHomeGallery from './CuralateHomeGallery';
import BrandComparison from './BrandComparison';
import BrandProductComparison from './BrandProductComparison';
import OurStoryVideo from './OurStoryVideo';
import FindGenexa from './FindGenexa';
import HeroPageIgaf from './HeroPageIgaf';
import SideBySideImageText from './SideBySideImageText';
import ImagebyImage from './ImagebyImage';
import InfoText from './InfoText';
import CenterText from './CenterText';
import HeroJoinTheMovement from './HeroJoinTheMovement';
import IgafCertifications from './IgafCertifications';
import TextBanner from './TextBanner';
import CenterImageSideText from './CenterImageSideText';
import ProductsCarousel from './ProductsCarousel';
import ProductsCarouselWithArrow from './ProductsCarouselWithArrow';
import CanadianLandingPage from './CanadianLandingPage';
import PatentTable from './PatentTable';
import OptionList from './OptionList';
import BannerWithCta from './BannerWithCta';
import CelebsList from './CelebsList';
import SocialMediaList from './SocialMediaList';
import LandingPageCompare from './LandingPageCompare';
import HeroBannerHomePage from './HeroBannerHomePage';

export default ({components = []}) => (
	<React.Fragment>
		{components.map((component, index) => {

			switch (component._type) {
				case 'heroHome':
					return (
						<HeroHome
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'heroCarousel':
					return (
						<HeroCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'heroPage':
					return (
						<HeroPage
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'heroPartner':
					return (
						<HeroPartner
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'duotoneBumper':
					return (
						<DuotoneBumper
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'testimonial':
					return (
						<Testimonial
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'testimonialCarousel':
					return (
						<TestimonialCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'ingredientOverview':
					return (
						<IngredientOverview
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'ingredientComparisonCarousel':
					return (
						<IngredientComparisonCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'doctorsBioGrid':
					return (
						<DoctorsBioGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'doctorsModalGrid':
					return (
						<DoctorsModalGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'imageCarousel':
					return (
						<ImageCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'certificationsList':
					return (
						<CertificationsList
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'certificationsGrid':
					return (
						<CertificationsGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'infoPage':
					return (
						<InfoPage
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'faqPage':
					return (
						<FaqPage
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'richTextColumns':
					return (
						<RichTextColumns
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'pressTestimonials':
					return (
						<PressTestimonials
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'pressGrid':
					return (
						<PressGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'storeLocator':
					return (
						<StoreLocator
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'instagramGrid':
					return (
						<InstagramGrid
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthForm':
					return (
						<FullWidthForm
							index={index}
							key={component._key}
							{...component.form}
						/>
					);
				case 'fullWidthKlaviyoForm':
					return (
						<FullWidthKlaviyoForm
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'hubspotForm':
					return (
						<HubspotForm
							index={index}
							key={component._key}
							CodeSnippet={component.CodeSnippet}
						/>
					);
				case 'productQuiz':
					return (
						<ProductQuiz
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'calloutCollection':
					return (
						<CalloutCollection
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'horizontalRule':
					return (
						<HorizontalRule
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'mediaWithRichText':
					return (
						<MediaWithRichText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthMedia':
					return (
						<FullWidthMedia
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthRichText':
					return (
						<FullWidthRichText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'fullWidthReviews':
					return (
						<FullWidthReviews
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'curalateHomeGallery':
					return (
						<CuralateHomeGallery
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'brandComparison':
					return (
						<BrandComparison
							index={index}
							key={component._key}
							{...component}
						/>
					);

				case 'landingPageCompare':
						return (
							<LandingPageCompare
								index={index}
								key={component._key}
								{...component}
							/>
						);
					
				case 'brandProductComparison':
					return (
						<BrandProductComparison
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'ourStoryVideo':
					return (
						<OurStoryVideo
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'findGenexa':
					return (
						<FindGenexa
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'heroPageIgaf':
					return (
						<HeroPageIgaf
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'sideBySideImageText':
					return (
						<SideBySideImageText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'imagebyimage':
					return (
						<ImagebyImage
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'infoText':
					return (
						<InfoText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'centerText':
					return (
						<CenterText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'heroJoinTheMovement':
					return (
						<HeroJoinTheMovement
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'igafCertifications':
					return (
						<IgafCertifications
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'textBanner':
					return (
						<TextBanner
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'optionList':
					return (
						<OptionList
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'centerImageSideText':
					return (
						<CenterImageSideText
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'productsCarousel':
					return (
						<ProductsCarousel
							index={index}
							key={component._key}
							{...component}
						/>
					);

				case 'productsCarouselWithArrow':
					return (
						<ProductsCarouselWithArrow
							index={index}
							key={component._key}
							{...component}
						/>
					);
				case 'canadianLandingPage':
					return (
						<CanadianLandingPage
							index={index}
							key={component._key}
							{...component}
						/>
					);
					case 'patentTable':
                  	return (
						<PatentTable
								index={index}
								key={component._key}
								{...component}
						/>
                  	);
					  case 'bannerWithCta':
						return (
						  <BannerWithCta
								  index={index}
								  key={component._key}
								  {...component}
						  />
						);
						case 'celebsList':
						return (
						  <CelebsList
								  index={index}
								  key={component._key}
								  {...component}
						  />
						);
						case 'socialMediaList':
							return (
							  <SocialMediaList
									  index={index}
									  key={component._key}
									  {...component}
							  />
							);
						case 'heroBannerHomePage':
							return (
							<HeroBannerHomePage
								index={index}
								key={component._key}
								{...component}
							/>
							);
				default:
					return null;
			}
		})}
	</React.Fragment>
);
