import React, { useState, useEffect } from 'react';
import chunk from 'lodash/chunk';
import Flickity from './Flickity';
import richText from '../serializers/richText';
import BlockContent from '@sanity/block-content-to-react';

const DESKTOP_SLIDE_OPTIONS = {
    cellAlign: 'left',
    contain: true,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    selectedAttraction: 0.1,
    friction: 0.8,
};

const CelebsList = ({ title, items = [] }) => {
    const [carousel, setCarousel] = useState(null);

    useEffect(() => {
       // console.log('CelebsList items:', items);
    }, [items]);

    const inventoryItems = items;
    const productTiles = inventoryItems.map(({ celeb_name, description, image }) => {
        return (
            <div className='row display-flex align-items-center'>
                <div className='col c3--md text-center slider-image'>
                    <picture>
                        <source
                            srcSet={`${image.url}?w=900&auto=format&q=75`}
                            media="(min-width: 1000px)"
                        />
                        <source
                            srcSet={`${image.url}?w=600&auto=format&q=75`}
                            media="(min-width: 600px)"
                        />
                        <img
                            alt={image.alt}
                            src={`${image.url}?w=400&auto=format`}
                            className='size--1x1'
                            onLoad={() => {
                                if (carousel) {
                                    carousel.resize(); // Ensure Flickity recalculates sizes after image loads
                                }
                            }}
                        />
                    </picture>
                </div>
                <div className='col c9--md'>
                    <p className='mb1 sans--sm-md fw--500'>
                        <div className="rich-text line-break">
                            <BlockContent
                                blocks={description}
                                serializers={richText}
                            />
                        </div>
                        <a href='javascript:void(0)' aria-label={celeb_name} className='sans--sm-md fw--600'>{celeb_name}</a>
                    </p>
                </div>
            </div>
        );
    });

    const desktopProductTileChunks = chunk(productTiles, 1);

    const onClickPrevious = () => {
        if (!carousel) return;
        carousel.previous();
    };

    const onClickNext = () => {
        if (!carousel) return;
        carousel.next();
    };

    const desktopProductCarousel = (
        <div className="row align--middle">
            <div className="col c1">
                {desktopProductTileChunks.length > 1 && (
                    <button
                        title="Product"
                        aria-label="Product"
                        onClick={onClickPrevious}
                        className="db link--opacity mra">
                        <img
                            className="db flip"
                            src="/images/icon-arrow-quiz.svg"
                            alt="Arrow"
                        />
                    </button>
                )}
            </div>
            <div className="col c10">
                <Flickity
                    sliderRef={sliderRef => setCarousel(sliderRef)}
                    key={inventoryItems.map(p => p.name).join('-')} 
                    options={DESKTOP_SLIDE_OPTIONS}
                    style={{ height: 'auto' }} // Set height to auto to adapt to content
                >
                    {desktopProductTileChunks.map((rowProducts, index) => (
                        <div key={index} className="x mr3">
                            <div className="row gutter--large--xl">
                                {rowProducts.map((tile, index) => (
                                    <div key={index} className="col c12">
                                        {tile}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Flickity>
            </div>
            <div className="col c1">
                {desktopProductTileChunks.length > 1 && (
                    <button
                        title="Product"
                        aria-label="Product"
                        onClick={onClickNext}
                        className="db link--opacity mla">
                        <img className="db" src="/images/icon-arrow-quiz.svg" alt="Arrow" />
                    </button>
                )}
            </div>
        </div>
    );

    return (
        <section className="custom-landing-page bg--white grid-container flush justify-content-center pr">
            <div id="product-quiz2" className="bg--white pr of--hidden">
                <div className='celebrities-container full-width display-flex align-items-center justify-content-center'>
                    <div className='page-heading full-width text-center align-items-center justify-content-center'>
                        <h1 className='tc fw--800 h1'>{title}</h1>
                    </div>
                    <div className='max-celebrities-container'>
                        {desktopProductCarousel}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CelebsList;
