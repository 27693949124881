import React from 'react';
import Link from './Link';

const BannerWithCta = ({
	_key,
	desktopBackground,
	mobileBackground,
	cta,
	ctaColor,
	ctaBackgroundColor
}) => {
	const handleScroll = (e) => {
		e.preventDefault(); // Prevent default anchor click behavior
		const target = document.getElementById('where-to-buy');
		if (target) {
			// Calculate target position with a 10% margin from the top of the viewport
			const offsetTop = target.getBoundingClientRect().top + window.scrollY;
			const marginOffset = window.innerHeight * 0.18; // 15% of the viewport height
			
			// Scroll to the target with the margin
			window.scrollTo({
				top: offsetTop - marginOffset, 
				behavior: 'smooth'
			});
		}
	};
	return (
<>
<div className="col c12 c12--lg testimonial-col" style={{'overflow':'hidden', 'display':'block'}}>
	<div className="testimonial__carousel-container" style={{'width':'100vw'}}>
		<div className="db image landing-banner-aspect-ratio" style={{
          position: 'relative', // For absolute positioning of child elements
        }}>
		<a href="javascript:void(0)" aria-label="shop-now" onClick={handleScroll}>
			<picture>
				<source
					srcSet={`${desktopBackground.url}?auto=format&amp;q=100`}
					media="(min-width: 800px)"
				/>
				<source
					srcSet={`${mobileBackground.url}?auto=format&amp;q=100`}
					media="(max-width: 799px)"
				/>
				<img
					alt="Our Story - Dr Shayna"
					src={`${desktopBackground.url}?w=680&amp;auto=format&amp;q=100`}
					data-cmp-ab="2"
					data-cmp-info="10"
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						objectPosition: 'center',
						position: 'absolute', // Ensure the image fills the container
						top: 0,
						left: 0,
					}}
				/>
			</picture>
			</a>
		</div>
	</div>
</div>
</>
)};
export default BannerWithCta;
